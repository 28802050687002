import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/redux/hooks';
import Link from 'next/link';
import ModalWaiter from '@/components/ModalWaiter';
import { Button } from '@mui/material';
import WaiterIcon from '@/assets/svg/Waiter';
import { useMain } from '@/contexts/main';
import { isMobile } from 'react-device-detect';
import ModalInfo from '@/components/ModalInfo';
import FilterIcon from '@/assets/svg/Filter';
import ModalFilter from '@/components/ModalFilter';

export default function Header() {
	const { list, responseWaiter, requestError, loading } = useAppSelector((state) => state.home);
	const { client, loginFields, responseLogin } = useAppSelector((state) => state.auth);
	const { storageLogin } = useMain();
	const [openWaiter, setOpenWaiter] = useState(false);
	const [showSnack, setShowSnack] = useState(false);
	const [openFilter, setOpenFilter] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');
	const personalData = responseLogin || storageLogin;
	// 	const showWaiter = !!(auth.client?.config_cliente?.chamada_garcom && loginFields && !loginFields?.config_qrcode?.pre_pago && personalData && isMobile );

	const showWaiter = !!(client?.config_cliente.chamada_garcom && loginFields && !loginFields.config_qrcode.pre_pago && personalData && isMobile );
	const showFilter = !!(!client?.config_cliente?.oculta_ordenacao && loginFields && !loginFields.config_qrcode.pre_pago && personalData && isMobile && false);

	function handleCloseWaiter() {
		setOpenWaiter(false);
	}

	function handleModal(state: boolean) {
		setShowSnack(state);
	}

	useEffect(() => {
		if (responseWaiter) {
			if (responseWaiter.mensagem) {
				setSnackMsg(responseWaiter.mensagem);
				setShowSnack(true);
			}

			if (responseWaiter.retorno) {
				handleCloseWaiter();
				setSnackMsg('Garçom chamado, por favor aguarde!');
				setShowSnack(true);
			}
		}
	}, [responseWaiter]);

	return (
		<>
			<header className="p-4" id="header">
				{!loading && (
					<nav className={`navMenu ${showWaiter && 'navMenuBetween'}`}>
						<Link href={list?.conteudo ? '/' : '/cardapio'}>
							<h1 className="title">{client?.config_cliente.titulo_mobile}</h1>
						</Link>
						{showFilter && (
							<Button variant="contained" className="callWaiter !text-white mx-2" onClick={() => setOpenFilter(true)}>
								<FilterIcon />
							</Button>
						)}
						{showWaiter && (
							<Button variant="contained" className="callWaiter" onClick={() => setOpenWaiter(true)}>
								<WaiterIcon />
							</Button>
						)}
					</nav>
				)}
			</header>
			<ModalWaiter open={openWaiter} handleClose={handleCloseWaiter} />
			<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
			<ModalFilter open={openFilter} setOpen={setOpenFilter} />
		</>
	);
}
