import React from 'react';

export default function BackIcon() {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.9625 0.200143C2.1119 0.199686 2.2595 0.229361 2.39448 0.286986C2.52946 0.344612 2.64838 0.428725 2.7425 0.533144L7.5725 5.93314C7.71958 6.09418 7.79999 6.29618 7.79999 6.50464C7.79999 6.71311 7.71958 6.9151 7.5725 7.07614L2.5725 12.4761C2.40276 12.6599 2.15885 12.7755 1.89442 12.7975C1.63 12.8194 1.36672 12.7459 1.1625 12.5931C0.958283 12.4404 0.829859 12.2209 0.80548 11.9829C0.7811 11.7449 0.862761 11.5079 1.0325 11.3241L5.5025 6.50014L1.1825 1.67614C1.06022 1.54404 0.98254 1.38317 0.958661 1.21258C0.934781 1.04198 0.965698 0.8688 1.04775 0.713526C1.12981 0.55825 1.25957 0.427381 1.42168 0.336399C1.58379 0.245418 1.77147 0.198134 1.9625 0.200143Z"
        fill="currentColor"
      />
    </svg>
  );
}
